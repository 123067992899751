$themeyellow: #ECFF00;

@mixin floating($bg: #fff, $borderclr: #636363, $label: #fff,$inputbg: transparent,$inputclr: #c7c7c7,$dropbg: #000,$dropspanclr:#fff,$drophover:#1d1c1c,$drophighlight:#1d1c1c,$righthover:#ecff03 ) {
    position: relative;
    .flag {
        // position: absolute;
        // top: 12px;
        // left: 12px;
        // width: 36px;
    }
    .react-tel-input .flag-dropdown ,.selected-flag:hover,.selected-flag:focus{
        border: 0px solid #59615f !important;
        background-color: #1a1b1c00 !important;
    }
    .react-tel-input .flag-dropdown.open .selected-flag {
        background: #ffffff03;
    }
    .react-tel-input .country-list{
        background-color: $dropbg;
        li{
           span{ color: $dropspanclr;}
           &:hover{
            background-color: $drophover;
           }
           &.highlight{
            background-color: $drophighlight;
        }
        }
        
    }
    .flagimg{
        border-radius:4px;
        top: 12px;
        left: 9px;
        width: 30px;
    }
    label {
        position: absolute;
        left: 16px;
        top: -6px;
        font-size: 12px;
        color: $label;
        // z-index: 9;
        z-index: 1;
        &:after {
            content: "";
            position: absolute;
            background: $bg;
            height: 1px;
            width: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: -1;
        }
    }
    .form-control {
        background: $inputbg;
        border-color: $borderclr;
        border-radius: 10px;
        height: 46px;
        color:$inputclr !important;
        appearance:auto !important;
        -moz-appearance: auto;
        -webkit-appearance: auto;
        font-size: 12px !important;
        &.leftspace{
            padding-left: 52px;
        }
        .form-control {
            width: 100%;
        }
    }
    textarea{
        height: 100px !important;
    }
    .right {
        position: absolute;
        right: 10px;
        top: 16px;
        color: #acacac;
        .fa.fa-eye:hover,.fa.fa-eye-slash:hover{
            color:$righthover !important
        }
    }
    .left{
        position: absolute;
        left: 13px;
        top: 9px;
        height: 30px;
    }
   
}

@mixin header($bg: #090909, $linkclr: #cccccc, $active: #fff) {
    .page_wrap {
        header {
            border-bottom: 0px solid #e3e3e3;
            background-color: $bg !important;
            padding: 10px 0;
            margin: 0;

            // top: 40px !important;
            .home_page_menu {
                .menu_main_navbar {
                    li {
                      
                        a.nav-link {
                            padding: 6px 18px;
                            font-size: 14px;
                            color: $linkclr ;
                            text-transform: capitalize;
                           &:hover{
                            padding: 6px 18px;
                            background: #ecff03;
                            border-radius: 10px;
                                color: #000 !important;
                           }
                            &.home_menu_btn1 {
                                @include themebtn
                            }

                            &.active {
                                color: $active;
                            }

                            &.home_menu_btn {
                                @include borderbtn
                            }

                            &.active {
                                color: $active;
                            }

                        }
                    }
                }
            }
        }

        &.without_ticker_banner header {
            top: 0 !important;
        }

        .bannersec {
            margin-top: 180px;
            margin-bottom: 30px;

            .bannercont {
                position: relative;
                padding-bottom: 70px;

                .banner_ad_wrapper {
                    h1 {
                        @media(max-width:767px) {
                            font-size: 30px;
                        }

                        @media(max-width:575px) {
                            font-size: 28px;
                        }
                    }

                    .borderbtn {
                        padding: 13px 20px;
                        font-size: 14px;
                    }
                }

                img {
                    position: absolute;
                }

                img.bannerconnect {
                    // bottom: 0;
                    transform: rotate(181deg);
                    filter: brightness(0.4);
                    left: -78px;
                }

                img.bannereth {
                    right: 0;
                }
            }

            @media(max-width:767px) {
                margin-bottom: 0;
                margin-top: 100px;
            }
        }
    }

}

@mixin buysellnav($bg: #000000E0, $borderbg: #fefefe9e, $navcolor: #fff,$linkclr:#ECFF03,$hoverclr:#fff) {
    border: 1px solid $borderbg;
    border-radius: 30px;
    background-color: $bg;
    nav {
        padding: 0;
        border-bottom: 1px solid #706C6C42;

        a {
            flex: 1;
            background-color: transparent;
            text-align: center;
            color: $navcolor !important;

            &:hover {
                border-bottom-color: transparent !important;
                color: $themeyellow;

            }

            &.active {
                background-color: $themeyellow !important;
                border-bottom: 1px solid #706C6C42 !important;
                color: #000000 !important;
            }

            &:first-child {
                border-radius: 30px 0 0 0;
            }

            &:last-child {
                border-radius: 0 30px 0 0;
            }
        }
    }
    .d-flex {
        a{
        color: $linkclr;
        text-decoration: underline;
        &:hover{
            color:$hoverclr !important;
        }
        }
    }
    .linkclr{
        text-decoration: underline;
    }
}

@mixin steps($btnbg: #050505, $btnclr : #fff, $afterbg: #393939){
   
        .stepbtns{
            position: relative;
            min-width: 450px;
            white-space: nowrap;
            &:after {
                content: "";
                background: $afterbg;
                height: 5px;
                width: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                z-index: -1;
            }
             button {
            background: $btnbg;
            color: $btnclr;
            border: 0;
            @media(max-width:575px){
                font-size: 12px;
            }
            
            span.fa {
                border: 2px solid gray;
                border-radius: 50%;
                padding: 2px;
                font-size: 10px;
                color: gray;
            }
            span.fa-check{
                background-color: green;
                border-color: transparent;
            }
            &.active{
                span{
                    border-color: $themeyellow;
                    color: $themeyellow;
                }
            }}
        }
       
        .grayandblack {
        font-size: 14px;
       }
       ul {
        padding-left: 18px;
        li{
        position: relative;
        &:before {
            content: "";
            position: absolute;
            background: #9f9f9f;
            height: 8px;
            width: 8px;
            display: block;
            border-radius: 50%;
            border: 1px solid white;
            left: -18px;
            top: 7px;
        }
      }
    }
    .tradeprice button p {
        font-size: 11px;
        font-family: roboto;
        color: #B4AEAE;
        line-height: 1.3;
    }
}

@mixin themetable($blackandwhite: #fff,$txtclr: #fff, $theadclr: #fff,$border:#050505, $rowbg:transparent,$rowclr: #fff1f4 ){ 
    table{ border-collapse: collapse;
     thead{
         tr{
             background-image:linear-gradient(40deg, #7384c696 20%, #e8699175 55%, #4493497a 80%);
            th{
             color: $theadclr !important;
             white-space: nowrap;
            //  font-size: 12px;
            } 
             
         }
     }
     tbody{
         tr{
              border: 10px solid $border !important;
              border-right: 0 !important;
              border-left: 0 !important;
              th{
                 color: #fff ;
                
              }
         }
        
     }
     tr{
        
         background-color: $rowbg !important;
         td{
             color: $rowclr ;
             background: #80808045;
             vertical-align: middle;
            //  white-space: nowrap;
             font-size: 12px;
             &:first-child{
                 border-radius: 20px 0 0 20px;
             }
             &:last-child{
                 border-radius:  0 20px 20px 0;
             }
         }
     }}
     .asrt-page-length .input-group-addon{
     background-color: transparent !important;
     span{
         color:$blackandwhite;
         font-size: 12px;
     }
     }
     select.form-control{
         background-color: transparent;
         border: 1px solid #fff !important;
         border-radius: 10px;
     }
     img{
         width: 35px;
     }
     #as-react-datatable-table-foot {
         .col-md-6{
             &:first-child {
                 display: none;
             }
             &:last-child{
                 margin-left: auto;
                nav{
                     border: 0 !important;
                     padding: 0;
                     ul {
                         width: 182px;
                         margin-left: auto;
                         border: 1px solid gray;
                         li{
                             a{
                                 padding: 0;
                             }
                         }
                       }
                 }
             }
         }
     }
     nav.nav{
         padding: 25px !important;
         max-width: 100%;
         flex-wrap: nowrap;
         overflow-x: auto;
         a{
             padding: 0;
             padding: 12px 10px  !important;
             border-radius: 10px;
             font-size: 11px;
             font-weight: 600;
             margin-right: 5px;
             color: $blackandwhite;
             white-space: nowrap;
             &.active,&:hover{
                 background: #ecff03 !important;
                 color:#000 !important;
             }
         }
     }
    //  .btn-bordered.white,
     select.form-control{
        border-color: $txtclr !important;
        color: $txtclr !important;
     }
 }








@mixin privacycare($bg: #050505,$head:#fff, $featurep:#CCCCCCE5) {
    background: $bg;
    position: relative;
    z-index: 999;

    .container-fluid {
        position: relative;
        margin-bottom: 122px;

        .bannerconnect,
        .connectr {
            position: absolute;
            filter: brightness(0.4);
        }

        .bannerconnect {
            transform: rotate(185deg);
            max-width: 67px;
            z-index: -1;
        }

        .connectr {
            right: 73px;
            transform: rotate(-75deg);
            max-width: 67px;
            top: 51px;
            z-index: -1;
        }

        .row_why_choose {
            padding-top: 45px;

            .card.calc_card {
                background-color: transparent;

                .card-body {
                    .feature_list {
                        p {
                            color: $featurep;
                            font-size: 14px;
                            // font-weight: 300;
                            font-weight: 500;
                        }

                        h5 {
                            color: $head;
                            font-size: 18px;
                        }

                    }
                }

            }

            @media(max-width:767px) {
                padding-top: 0;
            }
        }

        @media(max-width:1199px) {
            margin-bottom: 0;
        }

        .themebtn {
            padding: 13px 25px !important;
        }
    }
}

@mixin tablesec( $bg:#050505, $tablecolor:#0f0f0f, $table1color:#050505) {
    background: $bg;
    z-index: 99;
    position: relative;
    margin: 15px 0 68px;

    .tradetab {
        border: 1px solid #F5F5F5;
        border-radius: 20px;
        background: $table1color;
        position: relative;
        margin-top: 40px;

        .tradesearch {
            position: absolute;
            right: 0;
            top: 32px;
            right: 18px;
            gap: 10px;

            input {
                border-radius: 5px;
            }

            @media(max-width:991px) {
                position: unset;
                padding-top: 20px;
                padding-left: 21px;
            }
        }

        .bannerconnect,
        .connectrigth {
            position: absolute;
            filter: brightness(0.2);
        }

        .connectrigth {
            right: -40px;
            transform: rotate(-90deg);
            top: -56px;
        }

        .bannerconnect {
            left: -100px;
            transform: rotate(180deg);
            top: -137px;
            width: 180px;
        }

        .tablebg {
            position: absolute;
            aspect-ratio: 1/1;
            width: 60%;
            border-radius: 50%;
            background: $tablecolor;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: -1;
            left: 0;
            right: 0;
            margin: auto;
            top: -77px;
            bottom: 0;

            img {
                object-fit: contain;
                width: 434px;
                margin-top: -197px;
                aspect-ratio: 1/2;

                @media(max-width:991px) {
                    display: none;
                }
            }

            @media(max-width:1199px) {
                background-color: transparent;
            }
        }

        nav {
            border-bottom: 1px solid #878787;
            padding: 39px 0px 14px;

            a {
                background-color: transparent;
                border: 0;
                padding: 0 12px;

                img {
                    width: 45px;
                    border: 0.5px dashed transparent;
                    border-radius: 50%;
                    padding: 4px;
                    background: #000;
                }

                &.active,
                &:hover {
                    img {
                        border-color: #ECFF03;
                    }
                }
            }

            @media(max-width:991px) {
                padding: 20px 0px 14px;
            }
        }

        .tab-content {
            overflow: auto;
            color: #fff;

            &>div {
                min-width: 900px;
                // overflow-x: auto;
                max-width: 100%;
            }

            .tradinglist {
                height: 192px;
                overflow-y: auto;

                .tradelists {
                    margin-bottom: 20px;

                    p,
                    span {
                        font-size: 12px;
                        text-align: center;
                    }

                    border: 1px solid #FFFFFF82;
                    border-radius: 8px;
                    padding: 10px 6px;
                }
            }
        }
        
    }

    @media(max-width:991px) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

@mixin statusssec($headcolor: #fff, $pcolor: #CCCCCCE5) {
    text-align: center;
    padding: 62px 0;
    position: relative;
    overflow: hidden;
    margin-top: 135px;

    .container {
        position: relative;

        img {
            position: absolute;
            filter: brightness(0.3);
            max-width: 70px;
        }

        .bannerconnect {
            left: 0;
            transform: rotate(180deg);
            top: -45px;
        }

        .connectrigth {
            right: 50px;
            bottom: -64px;
        }

        h3 {
            font-weight: 700;
            color: $headcolor;

            @media(max-width:767px) {
                font-size: 15px;
            }
        }

        p {
            font-family: roboto;
            color: $pcolor;

            @media(max-width:767px) {
                font-size: 14px;
            }
        }
    }

  

    &:after {
        content: "";
        background-image: linear-gradient(134deg, rgb(248, 101, 141), rgb(0, 163, 251), rgb(3, 255, 72));
        width: 50%;
        height: 50%;
        position: absolute;
        top: 0;
        border-radius: 50%;
        left: 0;
        right: 0;
        margin: auto;
        z-index: -1;
        filter: blur(86px) brightness(0.4);
    }

    @media(max-width:991px) {
        margin-top: 54px;
    }
    @media(max-width:767px) {
        padding: 10px 0;
        margin-top: 0px;
    }
}

@mixin faqsec($faqcolor: #fff, $faqcolor1: #CCCCCCE5) {
    margin-top: 85px;
    position: relative;
    .borderbox {
        text-align: center;
        border: 1px solid gray;
        border-radius: 20px;
        margin-bottom: 17px;
        padding: 30px;
        position: relative;

        h3 {
            color: $faqcolor;
            font-weight: 400;
            font-size: 18px;
        }

        p {
            font-family: roboto;
            font-size: 14px;
            color: $faqcolor1;
            margin-bottom: 0;
        }

        &::after {
            background-image: linear-gradient(134deg, rgb(248, 101, 141), rgb(0, 163, 251), rgb(3, 255, 72));
            position: absolute;
            content: "";
            height: 50%;
            width: 50%;
            left: 0;
            top: 0;
            border-radius: 50%;
            z-index: -1;
            bottom: 0;
            margin: auto;
            right: 0;
            filter: blur(50px) brightness(0.5);
        }

    }

    .accordion {
        .card {
            background-color: transparent;
            border-bottom: 1px solid #707070;
            border-radius: 0;
            box-shadow: none;
            .card-header {
                margin-left: 0;
                scroll-margin-right: 0;
                padding-left: 0;
                a {
                    h5 {
                        color: #fff;
                    }
                }
            }

            .card-body {
                padding-left: 0;
                padding-right: 0;

                p {
                    color: #D5D5D5;
                    font-family: roboto;
                    line-height: 1.5;
                }

                ul {
                    padding-left: 17px;

                    li {
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            background-image: linear-gradient(134deg, rgb(248, 101, 141), rgb(0, 163, 251), rgb(3, 255, 72));
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            top: 7px;
                            left: -15px;
                        }

                        p {
                            color: #B4AEAE;
                            line-height: 1.5;
                        }
                    }
                }


            }
        }
    }

    .viewallfaq {
        h3 {
            color: $faqcolor;
            font-weight: 500;
            font-size: 18px;

            @media(max-width:575px) {
                display: none;
            }
        }
    }

    @media(max-width:991px) {
        margin-top: 35px;
    }
}

@mixin footersec($footcolor: #fff, $footp: #96F1AFD3, $emailbg: transparent ,$socialbright:unset ) {
    position: relative;
    // overflow: hidden;
    // margin-top: 115px;

    .container {
        padding: 50px 0;
        position: relative;

        img {
            position: absolute;
            filter: brightness(0.2);
        }

        .bannerconnect {
            transform: rotate(172deg);
            width: 180px;
        }

        .connectr {
            right: 0;
            bottom: 0;
            transform: rotate(172deg);
        }

        h2 {
            font-size: 24px;
            color: $footcolor;
            font-weight: 500;
        }

        p {
            font-family: roboto;
            color: $footp;
            font-size: 14px;
            margin-bottom: 25px;
        }

        .input-group {
            max-width: 450px;
            margin: auto;
            background-color: transparent;
            border: 1px solid white;
            border-radius: 12px;

            input {
                background: $emailbg;
                border-radius: 10px 0 0 10px;
                border: 0;
                height: 45px;
            }

            .themebtn {
                box-shadow: -23px 0 7px 0px #0b0b0b;
            }
        }

        @media(max-width:575px) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    &:after {
        content: "";
        background-image: linear-gradient(134deg, rgb(248, 101, 141), rgb(0, 163, 251), rgb(3, 255, 72));
        width: 50%;
        height: 50%;
        position: absolute;
        top: 0;
        border-radius: 50%;
        left: 0;
        right: 0;
        margin: auto;
        z-index: -1;
        filter: blur(86px) brightness(0.4);
    }

    .linksec {
        padding: 25px;
        border-top: 1px solid #393939;
        margin-top: 75px;

        ul {
            margin-bottom: 0;

        }

        @media(max-width:991px) {
            margin-top: 30px;
        }

        .footer_links {
            li {
                a {
                    color: #fff;
                    font-weight: 400;

                    &:hover {
                        color: $themeyellow;
                    }
                }
            }
        }

        .footerMidd_right {
            h3 {
                color: #fff;
                font-weight: 500;
                margin-right: 15px;
            }

            .socialLinks li a {
                background-image: linear-gradient(134deg, rgb(203, 66, 104), rgb(11, 107, 158), rgb(3, 139, 40));
                padding: 5px;
                height: 25px;
                width: 25px;
                display: flex;
                border-radius: 3px;
                color: #fff;
                font-weight: 500;
                filter: $socialbright ;

                &:hover {
                    background-image: linear-gradient(180deg, rgb(203, 66, 104), rgb(11, 107, 158), rgb(3, 139, 40));
                    transform: scale(1.05);
                }

                i {
                    color: #fff;
                    margin: auto;
                }
            }
        }
    }

    @media(max-width:991px) {
        margin-top: 35px;
    }
}

@mixin gradcircle($size: 300px, $top: -5%, $right: auto) {
    position: relative;

    &:after {
        content: "";
        background-image: linear-gradient(134deg, rgb(248 101 141 / 20%), rgb(0 163 251 / 19%), rgb(3 255 72 / 3%));
        height: $size;
        width: $size;
        position: absolute;
        z-index: 999;
        border-radius: 50%;
        filter: blur(78px);
        top: $top;
        right: $right;
        z-index: -1;
    }
}

@mixin themebtn($btnhoverbg: #ECFF03, $shadow: 0 0 5px 5px #222121) {
    background-color: #ECFF03;
    border: 1px solid #ECFF03;
    border-radius: 10px;
    // padding: 5px 20px !important;
    padding: 10px 30px !important;
    color: #000;
    font-size: 12px;
    font-weight: 700;
    box-shadow: $shadow;
    &:hover {
        border: 1px solid $btnhoverbg;
        background-color: transparent !important;
        color: $btnhoverbg !important;
    
            // border: 1px solid black;
            // background-color: transparent !important;
            // color: #000000 !important;
        
    }
}

@mixin borderbtn($colors:#fff, $bordercolor:#fff) {
    background-color: transparent;
    border-radius: 10px;
    padding: 5px 13px;
    color: $colors;
    font-size: 11px;
    border: 1px solid $bordercolor;

    &:hover {
        background-color: #ECFF03;
        border: 1px solid #ECFF03;
        color: #000 !important;
    }
}

@mixin themeselect($bg: transparent, $labelbg: #010101 ) {

    border: 1px solid #636363;
    padding: 14px;
    border-radius: 15px;
    position: relative;

    select {
        background: $bg !important;
        border: 0;
        appearance: auto;
        color: #000 !important;

        option {
            background-color: #000;
        }
    }

    button {
        padding: 2px 12px;
        font-size: 10px;
        margin-right: 5px;
        border-color: #1a1b1c;
        color: gray !important;
    }

    button.active,
    button:hover,
    button:focus {
        background-color: $themeyellow !important;
        border-color: $themeyellow;
        color: #000 !important;
    }

    label {
        position: absolute;
        top: -8px;
        background: $labelbg;
        padding-right: 6px;
        padding-left: 6px;
        color: #fff;
        font-size: 12px
    }

}

// .actionbtn {
//     min-width: 175px;
// }
.themeselect button {
    margin-bottom: 2px;
}