
.App {
    font-family: sans-serif;
    text-align: center;
  }
  
#content {
  width: 100%;
}
.react-grid-layout {

}
.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}
.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}
.react-grid-item {
  box-sizing: border-box;
}
.react-grid-item:not(.react-grid-placeholder) {
  background: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}
.react-grid-item.resizing {
  opacity: 0.9;
}
.react-grid-item.static > .react-resizable-handle{
  display: none;
}
.react-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
}
.react-grid-item .minMax {
  font-size: 12px;
}
.react-grid-item .add {
  cursor: pointer;
}
.react-grid-dragHandleExample {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
li b {
  font-size: 19px;
  line-height: 14px;
}
.tradeOrangeText{
    color: #f8652c !important;
}
.header-overview {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 15px 7px;
	height: 100%;
}
.headerOverviewGroup {
	display: flex;
	width: 78%;
	justify-content: space-around;
	margin-left: 15px;
}
.hoPriceConversion {
    margin-left: 0px !important;
}
.tradeGreenText{
  color: #09c178 !important;
}
.tradeRedText{
  color: #ce2d47 !important;
}
.headerOverviewStatus{
  margin: 0 0px;
}
.headerOverviewStatus h5{
  font-size: 12px;
  color: #3c3c3c;
  font-weight: 400;
  margin: 0;
}
.headerOverviewStatus h5 small {
    display: block;
    color: #787878;
    font-size: 12px;
}
.hoPriceConversion h3{
  margin: 0;
  font-size: 20px;
}
.derivativeTrade .selectPair{
    text-align: left;
}
.derivativeTrade .selectPair small{
    color: #000000;
}
.derivativeTrade .dropdown-toggle::after {
    position: absolute;
    right: -10px;
    top: 50%;
    text-align: left;
}
.selectCoinType .dropdown-menu {
    background: #fcfdff;
    width: auto;
    border-radius: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 9;
}
/* .selectCoinType .dropdown-header {
    margin: 0;
    padding: 0;
    float: left;
    width: 50%;
} */
.selectCoinType .dropdown-header h6{padding: 5px; color: #000; font-size: 14px; background-color: transparent; font-weight: 700; margin: 0;}
.selectCoinType .dropdown-header ul li{font-weight: normal; padding: 5px 0; cursor: pointer; color: #fff;}
.selectCoinType .dropdown-header ul li a {
    color: #000 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    font-size: 14px;
    padding: 3px 0;
}
.selectCoinType .dropdown-header ul li a:hover {
    color: #22b87b !important;
}
/* .selectCoinType .dropdown-header ul li:hover, .selectCoinType .dropdown-header ul li.active{background-color: #00b5eb;} */
.selectPair.dropdown-toggle {
    background: transparent;
    border: 0;
    font-size: 18px;
    font-weight: 500;
}
.selectCoinType{
  display: flex;
  align-items: center;
}
.tradeChart{
  height: 100%;
}
.tradeChart img{
  max-height: 100%;
  min-width: 100%;
  overflow: hidden;
}


.tableHead{
    display:flex;
    justify-content:space-between;
    align-items:center;
    border-bottom:1px solid #f0f0f0;
    margin-bottom:0px;
    padding-bottom:0px
}
.darkBox{
    margin-bottom:0px
}
.tableHead h4{
    font-size:16px;
    font-weight:600;
    margin:0 0 0 15px;
    color: #000000;
}
.tradeTableTitle span{
    text-align:left;
    color:#cdcdcd;;
    font-size:14px;
    font-weight:400;
    padding-top:5px;
    padding-bottom:5px
}
.tradeTableBodyRow span{
    text-align:left;
    color:#cdcecf;
    font-size:13px;
    padding-top:0px;
    padding-bottom:0px
}
.tradeTableBodyRow span i{
    margin-right:5px
}
.tradeTableBodyRow.highLight {
    background: #f7f7f7 !important;
}
.tradeTableBodyRow.highLight span{
    color:#bcbcbc;
    font-size:16px;
    font-weight:600;
    text-align:left
}
.derivative_orderbook .tradeTableBodyRow.highLight {
    position: static;
}
.greenText{
  color: #02c076 !important;
}
.pinkText{
  color: #a81e3a !important;
}
.tradeTableBodyRow.highLight span i{
    margin-right:5px
}

.tradeTableBodyRow.even{
    transition:.5s
}
.chartTradeSection img{
    width:100%;
    height:100%
}
.chartTradeSection{
    height:98%
}
/* .customScroll{
    max-height:200px
} */
.tradeFulltabbedTable {
    min-height: 319px;
}
.tradeFulltabbedTable .nav-tabs{
    border-bottom:1px solid #f0f0f0;
    border-radius: 0;
}
.tradeFulltabbedTable .nav-tabs .nav-link{
    color:#a3a3a3 !important;
    font-size:16px;
    font-weight:400
}
.tradeFulltabbedTable .nav-tabs .nav-link{
    background:0 0!important;
    border-top:0!important;
    border-left:0!important;
    border-right:0!important;
    border-radius:0!important;
    transition:none;
    text-transform:none;
    margin-right: 15px;
}
.orderBook .tradeFulltabbedTable .nav-tabs .nav-link{
    margin-right: 0;
}
.orderBook .nav-tabs{
    padding: 0;
}
.tradeFulltabbedTable .nav-tabs .nav-link:focus,
.tradeFulltabbedTable .nav-tabs .nav-link:hover{
    background:0 0!important;
    border-top:0!important;
    border-left:0!important;
    border-right:0!important;
    border-radius:0!important;
    border-bottom:2px solid #334dbc !important;
    color:#000000!important
}
.tradeFulltabbedTable .nav-tabs .nav-item.show .nav-link,
.tradeFulltabbedTable .nav-tabs .nav-link.active{
    background:0 0!important;
    border-top:0!important;
    border-left:0!important;
    border-right:0!important;
    border-radius:0!important;
    border-bottom:2px solid #334dbc !important;
    color:#000000!important
}
.tradeFulltabbedTable .table thead th{
    border-bottom:0;
    vertical-align:top
}
.tradeFulltabbedTable .table th{
    border-top:0;
    font-size:14px;
    font-weight:400;
    color:#cdcdcd;
    padding:5px 15px;
    white-space:nowrap;
}
.tradeFulltabbedTable .table td{
    border-top:0;
    font-size:13px;
    font-weight:400;
    color:#cac6c6;
    padding:1px 15px;
    white-space:nowrap;
}
.tradeFulltabbedTable .table td a{
  color: #334dbc;
}
.tradeFulltabbedTable .table td a:hover{
  text-decoration: underline;
}
.table-striped tbody tr:nth-of-type(2n+1){
    background-color:#ffffff;
}
.table-striped tbody tr:nth-of-type(2n){
    background-color:#ffffff;
}
ul.list2ColumnJustify{
    padding:10px 0 0 0
}
ul.list2ColumnJustify li{
    font-size:12px;
    color:#969696;
    padding:0 15px;
    display:flex;
    justify-content:space-between
}
ul.list2ColumnJustify li span{
    color:#d6b032
}
ul.list2ColumnJustify li span a{
    text-decoration:underline
}
ul.list2ColumnJustify li span a:hover{
    text-decoration:none
}
.tradeLimitMarket .nav-tabs{
    border-bottom:1px solid #27313d;
    padding-bottom:10px
}
.tradeLimitMarket .nav-tabs .nav-link{
    padding:2px 10px 4px;
    border-radius:5px;
    color:#969696;
    border:1px solid #19222d;
    margin-left:15px;
    font-size:14px;
    font-weight:600
}
.tradeLimitMarket .nav-tabs .nav-item.show .nav-link,.tradeLimitMarket .nav-tabs .nav-link.active{
    background:0 0;
    border-color:#d6b032;
    color:#d6b032
}
.tradeLimitMarket .nav-tabs .nav-link:focus,.tradeLimitMarket .nav-tabs .nav-link:hover{
    background:0 0;
    border-color:#d6b032;
    color:#d6b032
}
.tradeLimitMarket #nav-tabContent-tradeLimitMarket{
    padding:15px 15px 0
}
#leverageCurrentSliderValLabel{
    color:#969696;
    font-size:14px;
    margin:0 0 10px 0
}
#leverageSliderVal{
    color:#00b5eb;
    font-weight:700
}
#nav-tradeLimit .form-group label{
    font-size:14px;
    color:#969696;
    display:inline-block;
    vertical-align:middle;
    margin-bottom:0
}
#nav-tradeLimit .form-group label span{
    color:#fff
}
#nav-tradeLimit ul.list2ColumnJustify li{
    padding-left:0;
    padding-right:0;
    font-size:14px
}
#nav-tradeLimit ul.list2ColumnJustify li span{
    color:#969696
}
#nav-tradeLimit .form-group{
    margin-bottom:10px
}
.btn.btn-borderButton{
    color:#969696;
    border:1px solid #374454;
    text-transform:uppercase;
    transition:.5s;
    background:#19222d;
    padding:2px 7px;
    font-size:12px;
    box-shadow:none!important;
    outline:0
}
.btn.btn-borderButton:hover{
    color:#d4ae32;
    border:1px solid #d4ae32
}
.buySellBtn{
    display:inline-block;
    text-align:center;
    width:100%
}
.buySellBtn{
    margin-top:10px
}
.ButtonFullWidth {
    display: flex;
}
.BuyNavButton{
    background:#02c076;
    border:1px solid #02c076;
    color:#fff;
    text-transform:capitalize;
    font-weight:600;
    box-shadow:none!important;
    transition:.5s;
    font-size: 14px;
    flex: 1;
}
.BuyNavButton:hover{
    background:0 0;
    color:#02c076;
    border:1px solid #02c076
}
.SellNavButton{
    background:#a81e3a;
    border:1px solid #a81e3a;
    color:#fff;
    text-transform:uppercase;
    font-weight:600;
    box-shadow:none!important;
    font-size: 14px;
    flex: 1;
}
.SellNavButton:hover{
    background:0 0;
    color:#a81e3a;
    border:1px solid #a81e3a;
}

.placeOrderButton{
    background:#334dbc;
    border:2px solid #334dbc;
    color:#fff;
    text-transform:uppercase;
    font-weight:600;
    box-shadow:none!important;
    transition:.5s;
    font-size: 14px;
    flex: 1;
}
.placeOrderButton:hover{
    background:0 0;
    color:#334dbc;
    border:2px solid #334dbc
}

.number-input input[type=number]{
    -webkit-appearance:textfield;
    -moz-appearance:textfield;
    appearance:textfield
}
.number-input input[type=number]::-webkit-inner-spin-button,.number-input input[type=number]::-webkit-outer-spin-button{
    -webkit-appearance:none
}
.number-input button{
    -webkit-appearance:none;
    background-color:transparent;
    border:none;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    margin:0;
    position:relative
}
.number-input button:after,.number-input button:before{
    display:inline-block;
    position:absolute;
    content:'';
    height:2px;
    transform:translate(-50%,-50%)
}
.number-input button.plus:after{
    transform:translate(-50%,-50%) rotate(90deg)
}
.number-input input[type=number]{
    text-align:center
}
.number-input.number-input{
    border:1px solid #374454;
    width:100%;
    border-radius:.25rem;
    background:#121a23;
    position:relative
}
.number-input.number-input button{
    padding:0;
    width:20px;
    position:absolute;
    height:32px;
    outline:0
}
.number-input.number-input button.minus{
    padding-left:10px;
    right:8px
}
.number-input.number-input button:after,.number-input.number-input button:before{
    width:.6rem;
    background-color:#495057
}
.number-input.number-input input[type=number]{
    max-width:100%;
    width:85%;
    padding:.5rem;
    border:0 solid #374454;
    border-width:0 1px;
    font-size:1rem;
    height:2rem;
    color:#00b5eb;
    background:#121a23;
    text-align:right;
    border-left:0!important;
    border-radius:5px 0 0 5px
}
.number-input.number-input button.plus{
    border-right:1px solid #374454
}
.contractDetailsBTCUSDT .customScroll{
    max-height:380px;
    min-height:380px
}
.contractDetailsBTCUSDT ul.customScroll{
    max-height:330px;
    min-height:330px
}
.contractDetailsBTCUSDT ul.customScroll li{
    color:#969696;
    margin-bottom:7px;
    font-size:13px;
    line-height:18px
}
.contractDetailsBTCUSDT ul.customScroll li span{
    color:#fff
}
.contractDetailsBTCUSDT ul.customScroll li span.moderatorName{
    color:#00b5eb
}
.trollBox{
    position:relative;
    min-height:380px;
    margin:10px 10px 0
}
.trollBoxForm input{
    background:#121a23;
    border:1px solid #27313d;
    border-radius:0;
    box-shadow:none!important
}
.trollBoxForm input:focus{
    background:#121a23;
    border:1px solid #00b5eb
}
.trollBoxForm input[type=submit]{
    background:0 0;
    box-shadow:none!important;
    border:1px solid #d6b032;
    color:#d6b032;
    margin-left:5px;
    cursor:pointer;
    transition:.5s
}
.trollBoxForm input[type=submit]:hover{
    border:1px solid #00b5eb;
    color:#00b5eb
}
.bottomFixed{
    position:absolute;
    bottom:0;
    left:0;
    z-index:1
}
.tableHead .inputGroup .btn{
  padding: 0 !important;
  background: transparent;
  box-shadow: none !important;
  margin-right: 15px;
}
.tableHead h3{
    color:#d6b032;
    font-size:16px;
    font-weight:600;
    margin:10px 15px
}
.tableHead h2{
    font-size:24px;
    color:#d6b032;
    text-align:center;
    margin:12px 15px 15px;
    font-weight:600
}
.derivativeCalC {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.calCBtnGroup{
    margin-bottom: 4px;
}
.calcIcon a{
    font-size: 30px;
    color: #5a5a5a;
}
.calcIcon a:hover{
    font-size: 30px;
    color: #334dbc;
}
.btn.greyBtn {
    margin-right: 15px;
    box-shadow: none;
}
.flexTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.exchangeIcon{
    font-size: 18px;
    color: #9d9d9d;
}
.derivativeTrade .settingsSelect .form-group label{
    width: auto;
    display: inline-block;
}
.radioGroup {
    display: flex !important;
    flex-direction: row !important;
}
.orderRadio{
    margin-bottom: 5px !important;
    margin-right: 25px !important;
}
.radioGroup .MuiIconButton-label{
    color: #22b87b;
    font-size: 14px;
}
.radioGroup .MuiTypography-root{
    color: #000000;
    font-size: 14px;
}
.poList {
    margin: 15px 0;
    padding: 0 0 10px;
    border-bottom: 1px solid #e0e0e0;
}
.poList li{
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.poList li span{
    font-size: 14px;
    color: #000000;
    font-weight: 600;
}
.poList li label{
    font-size: 14px;
    color: #000000;
    font-weight: 600;
}
.poList li small{
    font-size: 14px;
    color: #5a5a5a;
}
.poContentFlex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.poContentFlex h2{
    margin-top: 0;
    font-weight: 600;
    font-size: 16px;
}
.poContentFlex h2 span{
    color: #02c076;
    font-size: 20px;
    font-weight: 600;
    display: block;
}
.pocfRight p{
    text-align: right;
    margin-bottom: 0px;
}
.pocfRight p small{
    display: block;
}
.flexTitle h3{
    margin-top: 10px;
}
.react-draggable{
    z-index: 1;

    
}
.react-grid-item.static{
    z-index: 9;
}
.derivativeTrade > .react-grid-layout .react-grid-item:nth-child(1) {
    z-index: 10;
}
.orderBookMain {
    position: relative;
}
.orderBookTwoColumn.singleColumnOrderBook .tradeTableBodyRow.even.highLight {
    position: static;
}
.orderBookTwoColumn .tradeTableBodyRow.even.highLight {
    position: absolute;
    bottom: -10px;
    top: unset;
    z-index: 10;
}




@media (max-width:1200px){
    .react-grid-layout{
        height: auto !important;
    }
    .react-grid-item:not(.react-grid-placeholder) {
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        width: calc(100% - 30px) !important;
        position: relative !important;
        transform: none !important;
        margin-bottom: 20px;
        margin-left: 15px;
        margin-right: 15px;
    }
    .react-grid-item > .react-resizable-handle{
        display: none !important;
    }
    .tradeFulltabbedTable .nav-tabs{
        margin-bottom: 15px;
    }
    .react-grid-item:not(.react-grid-placeholder) {
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    width: calc(100% - 30px) !important;
    position: relative !important;
    transform: none !important;
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right: 15px;
}
}
@media (max-width:859px){
    .header-overview{
        flex-wrap: wrap;
    }
    .react-grid-item:not(.react-grid-placeholder){
        height: auto !important;
    }
    .headerOverviewGroup{
        margin-top: 20px;
        width: 100%;
    }
    .headerOverviewStatus:first-child{
        margin-left: 0;
    }
    .tradeChart{
        height: 450px !important;
    }
}
@media (max-width:599px){
    .headerOverviewGroup{
        flex-wrap: wrap;
        margin-top: 0;
        justify-content: flex-start;
        margin-left: 0;
    }
    .headerOverviewStatus{
        margin-top: 6px;
        margin-bottom: 6px;
        margin-left: 0;
        margin-right: 0;
        width: 50%;
    }
    .orderBookMain.react-resizable{
        min-height: 350px !important;
    }
    .orderBookMain .scrollMainBox{
        min-height: 290px !important;
    }
    .tradeChartMain{
        min-height: 360px !important;
    }
    .orderPlaceMain .scrollMainBox{
        min-height: 420px !important;
    }
    .recentTradeMain .scrollMainBox{
        min-height: 290px !important;
    }
}