.bit_text {
    text-transform: uppercase;
    margin-bottom: 18px;
}

.bitcoins {
    position: relative;
    /* overflow: hidden; */
}

.buyborder input::placeholder {
    font-size: 14px !important;
}



.css-bh7o7s-ValueContainer {
   padding: 6px 20px !important;
}

.gk .spring {
    z-index: 0 !important;
    top: -160px !important;
    max-width: 360px !important;
}
.countdownspan span{
    color: #ffff;
}
.gk .bannerconnect {
    left: -4px !important;
    top: -21% !important;
    max-width: 55px !important;
    z-index: 0 !important;
}

.gk .connect {
    right: 1% !important;
    max-width: 68px !important;
    top: 78% !important;
    z-index: -1 !important;
}

.gk::after {
    content: "";
    background-image: linear-gradient(134deg, rgba(248, 101, 141, 0.2), rgba(0, 163, 251, 0.19), rgba(3, 255, 72, 0.03));
    height: 260px;
    width: 260px;
    position: absolute;
    z-index: 999;
    border-radius: 50%;
    filter: blur(78px);
    top: -40%;
    right: auto;
    z-index: -1;
}
.fullgradient{
    background-image: linear-gradient(134deg, rgba(248, 101, 141, 0.2), rgba(0, 163, 251, 0.19), rgba(3, 255, 72, 0.03));
    height: 260px;
    width: 260px;
    position: absolute;
    z-index: 999;
    border-radius: 50%;
    filter: blur(78px);
    top: 9%;
    right: auto;
    z-index: -1;
}
.fullgradient1{
    background-image: linear-gradient(134deg, rgba(248, 101, 141, 0.2), rgba(0, 163, 251, 0.19), rgba(3, 255, 72, 0.03));
    height: 260px;
    width: 260px;
    position: absolute;
    z-index: 999;
    border-radius: 50%;
    filter: blur(78px);
    top: 9%;
    right: 2%;
    z-index: -1;
}
.fullgradient2{
    background-image: linear-gradient(134deg, rgba(248, 101, 141, 0.2), rgba(0, 163, 251, 0.19), rgba(3, 255, 72, 0.03));
    height: 260px;
    width: 260px;
    position: absolute;
    z-index: 999;
    border-radius: 50%;
    filter: blur(78px);
    bottom: 12%;
    right: 50%;
    z-index: -1;
}

.bitcoins::after {
    content: "";
    background-image: linear-gradient(134deg, rgba(248, 101, 141, 0.2), rgba(0, 163, 251, 0.19), rgb(3 255 72 / 36%));
    height: 300px;
    width: 300px;
    position: absolute;
    border-radius: 50%;
    filter: blur(78px);
    top: -15%;
    left: -11%;
    z-index: -1;
}

.bitcoincompany::after {
    content: "";
    background-image: linear-gradient(134deg, rgba(248, 101, 141, 0.2), rgba(0, 163, 251, 0.19), rgb(3 255 72 / 26%));
    height: 300px;
    width: 300px;
    position: absolute;
    border-radius: 50%;
    filter: blur(78px);
    top: -6%;
    left: -11%;
    z-index: -1;
}

.spring {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -110px;
    bottom: 0;
    max-width: 483px;
    z-index: -1;
}
.spring11{
    top: 8% !important;
}
.no1 {
    color: #03FF48D3;
    font-size: 11px;
}

.graybtn:hover {
    background-color: #ECFF03 !important;
    border: 1px solid #ECFF03 !important;
    color: black !important;
    transition: 0.5s;
}

.graybtn.active {
    background-color: #ECFF03 !important;
    border: 1px solid #ECFF03 !important;
    color: black !important;
    transition: 0.5s;
}

.bannerconnect {
    position: absolute;
    transform: rotate(164deg);
    filter: brightness(0.4);
    left: -20px;
    max-width: 75px;
    top: -5%;
    z-index: -1;
}
.themebtn a{
   color: #000;
}
.dark_theme .themebtn a:hover, .dark_theme .themebtn:hover a{
    color: #ecff03 !important;
}
a.buyhover:hover .tradelists{
    border-color: #ecff03 !important;
}
.themebtn a:hover{
    color: #000 !important;
 }
 /* .graybtn a:hover{
    color: #fff !important;
 } */
.bannerconnect11{
    left: 14px !important;
    max-width: 80px !important;
    top: 16% !important;
    z-index: -1 !important;
}
.connect1 {
    position: absolute;
    max-width: 70px;
    right: -1%;
    z-index: -1;
}

.connect {
    position: absolute;
    right: -5%;
    max-width: 80px;
    top: 50%;
    z-index: -1;
}

.connectright {
    position: absolute;
    filter: brightness(0.3);
    bottom: 2%;
    right: -3%;
    max-width: 50px;
    z-index: -1;
}

.bitcoincompany .prof1 {
    margin-right: 0px !important;
    margin-top: 30px;
}

.boxarrow {
    background-color: #ecff03;
    color: black;
    width: 18px;
    height: 18px;
    padding: 3px;
    border-radius: 5px;
}

.btborder {
    border: 1px solid gray;
    padding: 16px 20px;
    border-radius: 14px;
    align-items: center;
}

.buyborder {
    border: 1px solid gray;
    padding: 20px 20px;
    border-radius: 12px;
}

.buyborder1 {
    border: 1px solid gray;
    padding: 20px 0px 0px 0px;
    border-radius: 12px;
}

.chance {
    overflow: auto;
}

.modal-backdrop {
    opacity: 0.5 !important;
}

.modal-backdrop.show {
    opacity: 0.7 !important;
}

/* .modal-backdrop.show::after {
   content: "";
   position: absolute;
   background: url("./assets/images/toss/connect.png");
   max-width: 200px;
   top: 20%;
} */
.one1 {
    border-right: 1px solid gray;
    padding: 20px 30px;
    align-items: center;
    display: flex;
    min-width: 243px;

}

.one2 {
    border-right: 1px solid gray;
    padding: 16px 30px;
    align-items: center;
    display: flex;
    /* min-width: 150px; */
}

.bit1 {
    color: #fff;
    font-size: 32px;
    font-weight: 600;
}

.buyborder1 .much {
    padding-left: 20px;
    border-bottom: 1px solid gray;
    padding-bottom: 12px;
}

body .makeStyles-dark-15, body .jss25 {
    background-color: #000 !important;
}
.dark_theme .makeStyles-dark-69{
    background: #000 !important;
}
.dark_theme .makeStyles-dark-130{
    background: #000 !important;
}
.border1 {
    border: 1px solid gray;
    padding: 14px 10px;
    border-radius: 16px;
    background: #3939399C 0% 0% no-repeat padding-box;
    text-align: center;
    min-height: 120px;
    max-height: 120px;
    height: 120px;
}

.lorem {
    color: #B4AEAE;
    font-size: 14px;
}

.btc-amount {
    color: #ECFF03;
    font-size: 14px;
    word-break: break-all;
}

.market {
    color: #F8658DC9;
    font-size: 12px;
}

.btborder .dropdown-toggle::after {
    margin-left: 0.8em !important;
    font-size: 15px !important;
}

.btcc {
    border: 1px solid #fffc !important;
    background: transparent !important;
    border-radius: 10px !important;
    padding: 8px 16px !important;
}

.btborder .dropdown-menu {
    background: #000 !important;
}

.btborder .dropdown-menu a {
    color: #fff;
}

.much {
    color: #fff;
}

.get {
    font-size: 12px;
    color: #B4AEAE;
}

.btborder .dropdown-menu a:hover {
    background-color: #ecff03 !important;
    color: #000 !important;
}

.buyborder .input-group-text {
    background: #ecff03 !important;
    border-radius: 0px 12px 12px 0px !important;
}

.jj .form-control {
    border-radius: 10px 0px 0px 10px !important;
}

.btborder input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
}

.buyborder input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
}

.btborder .floatinglabel {
    position: relative;
}

body.light_theme .makeStyles-dark-15,body.light_theme .jss25 {
    background-color: #eee !important;
}

.light_theme .tableborder .tradelists {
    background: #e9e5e6bf 0% 0% no-repeat padding-box;
}

.light_theme .no {
    color: #EC668BC9;
}

.light_theme .btborder .dropdown-menu {
    background: #eee !important;
}

.light_theme .btborder .dropdown-menu a {
    color: black;
}

.light_theme .bitcoins .graybtn {
    color: black;
    border: 1px solid black !important;
}

.btc-name {
    position: absolute;
    top: 28%;
    right: 4%;
    margin-bottom: 0px !important;
    font-size: 12px;
    color: #B4AEAE;
}

.light_theme .vendorbtn {
    border: 1px solid black !important;
    color: black !important;
}

.light_theme .modalbtns {
    border: 1px solid black !important;
    color: black !important;
}

.light_theme .modal-content {
    background: #eeeeeee0 !important;
}

.light_theme .borders p {
    color: #000000 !important;
}

.light_theme .lists li p {
    color: #000000 !important;
}

.light_theme .btc-amount {
    color: #ECFF03;
    font-weight: 600 !important;
}

.light_theme .market {
    color: #F8658DC9;
}

.light_theme .border1 {
    border: 1px solid #8080806b;
    background: #39393926 0% 0% no-repeat padding-box;
}

.light_theme .btcc {
    border: 1px solid black !important;
    color: black !important;
}

.light_theme .limits {
    color: #B4C20C !important;
    font-weight: 600 !important;
}

.light_theme .tww {
    color: #F8658D !important;
    font-weight: 600 !important;
}

.bitcoincompany {
    position: relative;
}

.bitcoins .graybtn {
    background: transparent !important;
    color: #fff;
    border: 1px solid #ffffff !important;
    padding: 10px 20px !important;
    border-radius: 12px;
}

.bitcoins .graybtn:hover {
    background-color: #ECFF03 !important;
    border: 1px solid #ECFF03 !important;
    color: black !important;
}

.tableborder {
    border: 1px solid gray;
    padding: 20px 20px;
    border-radius: 20px;
}

.tableborder .aqua {
    font-size: 13px;
}

.limits {
    color: #ffff;
    font-size: 13px;
    margin-bottom: 6px !important;
}

.limits2 {
    color: #03FF48D3;
    font-size: 13px;
}

.amount {
    color: #ffff;
    font-size: 14px;
    font-weight: 500;
}

.gtt .themebtn {
    padding: 0px 30px !important;
    color: #000 !important;
    box-shadow: unset !important;
}

.gwiXjR {
    color: #ffff !important;
    /* background: transparent !important; */
    background-image: linear-gradient(40deg, rgb(115 132 198 / 78%) 20%, rgb(232 105 145 / 70%) 55%, rgb(68 147 73 / 70%) 80%);
    border-radius: 10px 10px 0px 0px;
}
.rdt_TableHead{
    margin-top: 2%;
}
.fSMKzn {
    color: #ffff !important;
    background-color: #ffffff21 !important;
    margin-top: 2%;
}
/* .sc-iuOPfm {
    color: #fff !important;
}
.sc-iuOPfm option{
    background: #000 !important;
    color: #000 !important;
} */
.light_theme .gwiXjR {
    background-image: linear-gradient(40deg, rgb(115 132 198 / 78%) 20%, rgb(232 105 145 / 70%) 55%, rgb(68 147 73 / 70%) 80%) !important;
    border-radius: 12px 12px 0px 0px;
}

.rdt_TableCol_Sortable {
    font-weight: 700;
}

.fIGDtW {
    color: #ffff !important;
    background: transparent !important;
}

.gUwWrl {
    color: #fff !important;
    /* background-color: #ffffff24 !important; */
    background-color: transparent !important;
}

.rdt_Pagination svg {
    fill: #fff !important;
}

.light_theme .rdt_Pagination svg {
    fill: #000 !important;
}

.light_theme .gtGQZy {
    color: #000 !important;
}

.tab-content {
    /* overflow: auto; */
}

.profileContent .tab-content {
    overflow: unset !important;
}

.cion_table_sectio .tab-content {
    overflow: unset !important;
}

.tab-content1 {
    min-width: 900px;
    overflow-x: auto;
    max-width: 100%;
}

.tradinglist {
    height: 280px;
    overflow-y: auto;
    padding: 0px 10px 10px 10px;
}

.table1 {
    border: 1px solid red !important;
    border-radius: 8px;
    padding: 10px 6px;
}

.namelist {
    color: #FFFFFF;
    margin-bottom: 0px !important;
    font-size: 13px;
}

.no {
    color: #EC668BC9;
    font-size: 12px;
}

.table-header {
    padding: 0px 20px 8px 20px;
    border-bottom: 1px solid gray;
}

.tableborder .tradelists {
    border: 1px solid rgba(255, 255, 255, 0.5098039216);
    border-radius: 8px;
    padding: 16px 8px;
    background: #3D3C3C70 0% 0% no-repeat padding-box;
}

.makeStyles-dark-15 ,.jss25 {
    background-color: black !important;
    background: unset !important;
}

.arl {
    font-size: 22px;
}

.back {
    color: #fff !important;
    margin-top: 7% !important;
    margin-left: 0%;
}

.back:hover {
    color: #ecff03 !important;
}

.modal-header {
    border-bottom: 1px solid #80808075 !important;
}

.modal-content {
    border: 1px solid #808080cc !important;
    /* background: #9e9e9e24 !important;     */
    background: #000000b5 !important;
    opacity: 1;
    border-radius: 12px !important;
    overflow: hidden;
}

.modal-title {
    color: #ffff;
    font-size: 16px;
}

.modalbtns {
    padding: 4px 8px !important;
    border-radius: 8px !important;
    border: 1px solid #fff !important;
    background: transparent !important;
    color: #fff !important;
    height: unset !important;
    font-size: 12px !important;
}

.submod-title {
    color: gray;
    font-size: 10px !important;
}

.borders {
    border: 1px solid gray;
    padding: 16px;
    border-radius: 16px;
}

.borders p {
    color: #B4AEAE;
    font-size: 11px;
    line-height: 28px;
}

.vendorbtn {
    background: transparent !important;
    border: 1px solid #ffffffb0 !important;
    border-radius: 8px !important;
    padding: 12px 22px !important;
}
.addressproof .MuiInput-underline {
    height: 36px !important;
}
.light_theme .addressproof .MuiInput-underline {
    height: 52px !important;
}
.light_theme .terms_page h4{
    color: black;
}
.light_theme #footer {
    background: #eeeeee;
    margin-top: 0px !important;
}
.beforelog .MuiList-padding{
    justify-content: end;
}
.light_theme .dateformat{
    /* color: #eaeaea !important; */
    color: #dbdbdb !important;
}
.light_theme .descc{
    /* color: #8c8c8c !important; */
    color: #ffff !important;
}
.docus{
    position: relative;
}
.docus .labelname{
    position: absolute;
    left: 2%;
    top: -10%;
    font-size: 12px;
    color: #ffff;
    background: #010101;
    width: fit-content !important;
    z-index: 2;
}
.light_theme .docus .labelname{
    background: #eeeeee !important;
    width: fit-content !important;
    z-index: 2;
}
.docus .labelname1 {
    top: -8% !important;
}
.dark_theme .makeStyles-dark-76{
    background: #000 !important;
}
.dark_theme .floatinglabel label {
    /* background: #010101 !important; */
    background: #010101ed !important;
    width: fit-content !important;
}
.light_theme .floatinglabel label {
    background: #eeeeee !important;
    width: fit-content !important;
}
.vendorbtn:hover {
    background-color: #ECFF03 !important;
    border: 1px solid #ECFF03 !important;
    color: black !important;
}

/* .dashboard_container #footer {
    display: none;
} */
.mobilelog  li a{
    color: #ffff !important;
    margin-left: 12px;
}
.light_theme .mobilelog  li a{
    color: gray !important;
    margin-left: 12px;
}
.modal-body::after {
    content: "";
    background-image: linear-gradient(134deg, rgb(248 101 141 / 0%), rgb(81 255 89 / 48%), rgb(3 255 72 / 5%));
    height: 120px;
    width: 180px;
    position: absolute;
    border-radius: 50%;
    filter: blur(78px);
    top: 6%;
    right: 0%;
    z-index: 0;
    z-index: -1 !important;
    overflow: hidden;
}
.mobileflex{
    display: flex;
    justify-content: space-between;
}
.d-flexj {
    display: flex;
}

.connectright1 {
    position: absolute;
    max-width: 36px;
    top: 0%;
    left: 0%;
    z-index: 2;
}

.modal-dialog .modal-header {
    padding-top: 30px;
}

.modalbtns:hover {
    border: 1px solid #ECFF03 !important;
    background: #ECFF03 !important;
    color: black !important;
}

.spring1 {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -5px;
    bottom: 0;
    max-width: 500px;
    z-index: 0;
}

.allbtn::before {
    content: "";
    background-image: linear-gradient(134deg, rgb(248 101 141 / 0%), rgb(0 163 251 / 52%), rgb(3 255 72 / 5%));
    height: 100px;
    width: 150px;
    position: absolute;
    border-radius: 50%;
    filter: blur(78px);
    top: 70%;
    left: 0%;
    z-index: 0;
}

.allbtn::after {
    content: "";
    background-image: linear-gradient(134deg, rgb(248 101 141 / 0%), rgb(244 67 54 / 52%), rgb(3 255 72 / 5%));
    height: 100px;
    width: 150px;
    position: absolute;
    border-radius: 50%;
    filter: blur(78px);
    top: 65%;
    right: 0%;
    z-index: 0;
}

.lists ul {
    padding-left: 0px !important;
    list-style-type: none;
}

.lists li {
    position: relative;
    margin-top: 12px;
    color: #B4AEAE;
    font-size: 11px;
    display: flex;
    align-items: center;
}

.lists li p {
    margin-bottom: 0px !important;
    margin-top: 0px;
    font-size: 12px;
}

.modalz {
    position: relative;
    overflow: hidden;
}

/* .vv1{
    position: fixed;
    z-index: -1;
    max-width: 70px;
    left: -38px;
    top: 5px;
} */
.greengradient {
    position: absolute;
    background-image: linear-gradient(134deg, rgb(248 101 141 / 0%), rgb(79 214 117), rgb(140 212 160 / 0%));
    height: 100px;
    width: 150px;
    border-radius: 50%;
    filter: blur(78px);
    top: 65%;
    right: 0%;
    z-index: -1;
}

.tickimg {
    margin-right: 10px;
    max-width: 24px;
    margin-top: -2px;
}

.lists li::after {
    content: "";
    background: url("./assets/images/ticks.png");
    background-repeat: no-repeat;
    background-size: 30px;
    position: absolute;
    top: 2%;
    left: 2%;
}

/* @media only screen and (max-width: 1200px) {
    .buyss {
        display: none !important;
    }
} */
@media only screen and (max-width: 1279px) {
.dashboard_login
{
    display: unset !important;
    justify-content: space-between;
}
}

@media only screen and (max-width: 991px) {
   
    .bitcoins {
        overflow: hidden;
    }

}

@media only screen and (max-width: 991px) {
    .spring1 {
        display: none;
    }

    .bitcoins .connect {
        display: none;
    }

    .bannerconnect {
        left: 5px;
        max-width: 75px;
        top: 0%;
    }

    .uu {
        display: unset !important;
    }

    .no1 {
        font-size: 9px;
    }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    .btc-amount {
        font-size: 11px;
    }
}

@media only screen and (max-width: 575px) {
    .one2
    {
       padding-left: 15px !important;
       padding-right: 15px !important; 
    }
    .gk .spring {
        max-width: 260px !important;
    }
    .dark_theme .themenav .d-flex a {
        font-size: 10px;
    }
    .bitcoins::after {
        background-image: linear-gradient(134deg, rgba(248, 101, 141, 0.2), rgb(0 163 251 / 0%), rgb(3 255 72 / 36%));
    }

    .d-flexj {
        display: unset;
    }

    .spring {
        max-width: 250px;
    }

    .bit1 {
        font-size: 25px;
    }

    .subhead {
        font-size: 14px;
    }

    .btborder .floatinglabel .form-control {
        height: 36px !important;
        color: #fff !important;
        font-size: 10px !important;
    }
    .light_theme  .btborder .floatinglabel .form-control {
        color: #000 !important;
    }

    .btborder {
        padding: 16px 6px;
    }

    .btc-name {
        top: 20%;
    }

    .bitcoins .graybtn {
        padding: 10px 14px !important;
        font-size: 12px;
    }

    .bitcoins .themebtn {
        padding: 10px 16px !important;
    }
}
.Subscribe .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show>.btn-primary.dropdown-toggle {
    background-color: #000 !important;
    border-color: #000 !important;
}
/* .alloffers .PrivateHiddenCss-mdDown-26 {
    width: 100%;
} */
.home_page_header_banner{
    overflow: hidden;
}
.buyss{
    margin-left: 20px;
}
.buyss button{
    font-size: 13px;
    padding: 8px 14px !important;
}
.buyss .dropdown-menu{
    background: #000;
    border-radius: 6px;
}
.light_theme .buyss .dropdown-menu {
    background: #eeeeee;
}
.buyss .dropdown-menu a{
    color: #fff;
}
.light_theme .buyss .dropdown-menu a{
    color: #000;
}
.light_theme .floatinglabel .form-control{
    color: #000 !important;
}
.buyss .dropdown-menu a:hover{
    color: #000 !important;
}
.buyss .m-left{
    margin-left: 12px;
}
.buyss .dropdown-menu .dropdown-item{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.headerdropdown .dropdown-menu a:hover,
.headerdropdown .dropdown-menu a:focus

{
     background: #ecff03 !important;
}
.buyss .iconss{
    max-width: 25px !important;
    /* filter: invert(1); */
    margin-right: 8px;
}
.light_theme .buyss .iconss{
    max-width: 25px !important;
    filter: unset;
    margin-right: 8px;
}
.fa-bell{
    background: transparent linear-gradient(158deg, #00A3FBD8 0%, #F8658DC9 36%, #03FF48D3 78%, #03FFE0 100%) 0% 0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.buyss .dropdown-menu a:hover .iconss{
    color: #000 !important;
    filter: unset;
}
.light_theme .loginhover{
    color: #000 !important;
}
.dashboard_login
{
    display: flex;
    justify-content: space-between;
}
/* .alloffers .PrivateHiddenCss-mdDown-26,.PrivateHiddenCss-mdDown-97 {
    width: 100%;
} 
 .alloffers .PrivateHiddenCss-mdDown-80{
    width: 100%;
}
.alloffers .PrivateHiddenCss-mdDown-141{
    width: 100%;
} 
.alloffers .PrivateHiddenCss-mdDown-128{
    width: 100%;
}
.alloffers .PrivateHiddenCss-mdDown-202{
    width: 100%;
} */
.alloffers .mobilelog{
    display: flex;
    justify-content: space-between;
}
header.dropheader div div:last-child {
    width: 100%;
}

.one2 div,.one1 div
{
    width: 100%;
    text-align: center;
}
.one2_col_wdi.one2
{
    min-width: 240px !important;
}
.iner_drop_versiotwo .dropdown-toggle::after{
    border-top: 0.5em solid !important;
    border-right: 0.5em solid transparent !important;
    border-bottom: 0;
    border-left: 0.5em solid transparent !important;
}